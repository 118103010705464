<h2 class="text-center pt-5">Ventas Mensuales</h2>
<div class="card-header border-0 pt-5">
  <!-- begin::Title-->
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder-normal fs-3 mb-1" *ngFor="let total of totales">{{total.Total | numberFormat}} -  Mes Actual</span>
<!-- ultimos 12 meses -->
    <span class="text-muted fw-bold fs-7">Ventas Últimos Meses</span>
  </h3>
  <!-- end::Title-->

  <!-- begin::Toolbar-->
  <div class="card-toolbar">
    <!-- begin::Menu-->
    <!-- <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets//media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1> -->

    <!-- end::Menu-->
  </div>
  <!-- end::Toolbar-->
</div>
<!-- end::Header-->
<div *ngIf="ventas == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
  <div>No hay datos para mostrar</div>
</div>
<!-- begin::Body-->
<div class="card-body" *ngIf="!load">
  <!-- begin::Chart-->
  <div #chartRef id="kt_charts_widget_1_chart" [style.height]="'350px'">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
      [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
  <!-- end::Chart-->
</div>
