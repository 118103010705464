import { VentaResponse } from './../../../../../../models/venta';
import { PipeModule } from './../../../../../../modules/pipes/pipe.module';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Venta } from 'src/app/models/venta';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { DashboardService } from 'src/app/pages/services/dashboard.service';
import { getCSSVariableValue } from '../../../../../kt/_utils';
import { NumberFormatPipe } from 'src/app/modules/pipes/number.pipe';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'app-charts-widget3',
  templateUrl: './charts-widget3.component.html',
})
export class ChartsWidget3Component implements OnInit, OnChanges {
  @Input() form: FormData;
  chartOptions: any;
  load = true;
  ventas: Venta[]| any;
  loading = true;
  showAlert = false;

  constructor(
    private dashboardProvider: DashboardService,
    private formBuilder: FormBuilder,
    private userService: UsuarioService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.graficoMesxDia();
    console.log(changes);
  }

  ngOnInit(): void {
    this.graficoMesxDia();
  }

  graficoMesxDia() {
    const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;

    this.dashboardProvider.getVentasMesxDia(search, idEmpresa).subscribe({
      next: (venta) => {
        if (venta.data) {
          const datosGrafica = venta.data.map((y) => y.Total);
          const diasGrafica = venta.data.map((x) => x.Mes);

          this.chartOptions = getChartOptions(350, diasGrafica!, datosGrafica);

          this.ventas = [...venta.data];
          window.dispatchEvent(new Event('resize'));
          // console.log(this.chartOptions)
          this.load = false;
        }
      },
      error: (err) => console.error(err),
    });
  }

  // // Filtro
  // onSubmit( form: NgForm ) {
  //   const idEmpresa = this.userService.usuario.id_empresa
  // this.formData = this.myGroup.value;
  // console.log(this.myGroup.value);
  //   let search:any = {}
  //     if(this.formData['mes']){
  //       search.mes = this.formData['mes']
  //     }
  //     if(this.formData['tipoProducto']){
  //       search.tipoProducto = this.formData['tipoProducto']
  //     }
  //     console.log(search)
  //     this.dashboardProvider.getVentasMesxDia(search, idEmpresa).subscribe({
  //       next: (venta)=>{
  //         if(venta.data){
  //       const datosGrafica = venta.data.map(y => y.Total)
  //       const diasGrafica = venta.data.map(x => x.Mes)

  //       this.chartOptions = getChartOptions(350, diasGrafica!, datosGrafica);

  //       this.ventas = [...venta.data];

  //       // window.dispatchEvent(new Event('resize'))
  //       // console.log(this.ventas)
  //       this.load = false;

  //     }
  //   },
  //     error: (err) => console.error(err)
  //   })
  //     }

  // ngOnInit(): void {

  //   this.dashboardProvider.getVentasMesxDia(userService.usuario.id_empresa).subscribe({
  //     next: (venta)=>{
  //       if(venta.data){
  //     const datosGrafica = venta.data.map(y => y.Total)
  //     const diasGrafica = venta.data.map(x => x.Mes)

  //     this.chartOptions = getChartOptions(350, diasGrafica!, datosGrafica);

  //     this.ventas = [...venta.data];
  //     window.dispatchEvent(new Event('resize'))
  //     // console.log(this.ventas)
  //     this.load = false;
  //   }
  // },
  //   error: (err) => console.error(err)
  // })
  // }
}

function getChartOptions(height: number, x: any[], y: any[]) {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-info');
  const lightColor = getCSSVariableValue('--bs-light-info');

  return {
    series: [
      {
        name: 'Venta',
        data: y,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      type: 'category',
      categories: x,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (
          value: number,
          decimalLength: number = 0,
          chunkDelimiter: string = '.',
          decimalDelimiter: string = ',',
          chunkLength: number = 3
        ): string {
          let result =
            '\\d(?=(\\d{' +
            chunkLength +
            '})+' +
            (decimalLength > 0 ? '\\D' : '$') +
            ')';
          let num = value.toFixed(Math.max(0, ~~decimalLength));

          return (
            '$' +
            (decimalDelimiter
              ? num.replace('.', decimalDelimiter)
              : num
            ).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter)
          );
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
}
