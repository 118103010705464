export enum PermisosRol{
    VER_DASHBOARD = 1,
    VER_PRODUCTO = 2,
    VER_VENTAS = 3,
    VER_LEADS = 4,
    VER_CONFIGURACION = 5,
    VER_CAMPANIA = 6,
    VER_CATEGORIA = 7,
    ADD_PRODUCTO = 8,
    ADD_CATEGORIA = 9,
    ADD_CAMPANIA = 10,
    UPDATE_VENTAS = 11,
    UPDATE_PRODUCTO = 12,
    TODOS = 13,
    ADD_PRODCAMPANIA = 14,
    DELETE_CAMPANIA = 15,
    DELETE_PRODUCTO = 16,
    DELETE_CATEGORIA = 17,
    UPDATE_CAMPANIA = 18,
    DELETE_PRODCAMPANIA = 19,
    VER_REPORTEVENTASCONFIG = 20,
    VER_VENTASMANUALES = 21,
}