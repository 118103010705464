import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { DashboardService } from '../../services/dashboard.service';
import { Venta } from 'src/app/models/venta';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() form: FormData;
  tipos: Venta[] | any;
  ventas: Venta[];
  showAlert =false;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  constructor(
    private dashboardProvider: DashboardService,
    private userService: UsuarioService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.montoMensual();
    this.pieChart();
    console.log(changes);
  }

  ngOnInit(): void {
    this.montoMensual();
    this.pieChart();

  }


montoMensual(){
  const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;
  this.dashboardProvider.getVentasMensuales(search, idEmpresa).subscribe((venta: any)=>{
    (venta.data)
    this.ventas = venta.data;
  })
}

  pieChart(){
    const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;
  this.dashboardProvider.getProdVendidosxTipo(search,idEmpresa).subscribe({
    next: (tipo)=>{
      if(tipo.data){ 
    const datosGrafica= tipo.data.map(y => y.Total)
    const tipos = tipo.data.map(y => y.tipo)

    this.pieChartData.labels = tipos

    this.pieChartData.datasets[0].data = datosGrafica
    // console.log(this.pieChartData)
    this.tipos = tipo.data;
    // console.log(this.tipos)
    window.dispatchEvent(new Event('resize'))
    // console.log(this.ventas)
    this.chart?.update();
  }
},
  error: (err) => console.error(err)
})
}


  // Pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    }
  };
  public pieChartData: ChartData<'pie', any[]> = {
    // labels: [ [ 'Download', 'Sales' ], [ 'In', 'Store', 'Sales' ], 'Mail Sales' ],
    datasets: [ {
      // data: [1,1,2]
      data:[]
    } ]
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [ DatalabelsPlugin ];

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }


  addSlice(): void {
    if (this.pieChartData.labels) {
      this.pieChartData.labels.push([ 'Line 1', 'Line 2', 'Line 3', 'Line 4' ]);
    }

    this.pieChartData.datasets[0].data.push(400);

    this.chart?.update();
  }

  removeSlice(): void {
    if (this.pieChartData.labels) {
      this.pieChartData.labels.pop();
    }

    this.pieChartData.datasets[0].data.pop();

    this.chart?.update();
  }

  changeLegendPosition(): void {
    if (this.pieChartOptions?.plugins?.legend) {
      this.pieChartOptions.plugins.legend.position = this.pieChartOptions.plugins.legend.position === 'left' ? 'top' : 'left';
    }

    this.chart?.render();
  }

  toggleLegend(): void {
    if (this.pieChartOptions?.plugins?.legend) {
      this.pieChartOptions.plugins.legend.display = !this.pieChartOptions.plugins.legend.display;
    }

    this.chart?.render();
  }
}

