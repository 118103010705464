<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard - Productos</h1>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Inicio</li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Dashboards</li>
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <li class="breadcrumb-item text-muted">Productos</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
    </div>
</div>
<!-- Filtro -->

    <!-- Card -->

    <div class="row pb-4">
        <div class="col">
          <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..." > -->
            <div class="card-body">
               <!--begin::Background-->
               <span class="svg-icon svg-icon-2 me-2" style=" 
               position: absolute;
               top: 40%;
               left: 40%;
               width: 100%;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z" fill="black"/>
                  </svg>
                  
               </span> 
               <!--end::Background-->
              <h1 class="card-title text-center fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let sku of skus">{{sku.totalSKU}}</h1>
              <p class="card-text text-center text-muted">Total Sku</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
              <!--begin::Background-->
              <span class="svg-icon svg-icon-2 me-2" style=" 
              position: absolute;
              top: 40%;
              left: 30%;
              width: 100%;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                  <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="black"/>
                  <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="black"/>
                  </svg>                  
              </span> 
              <!--end::Background-->
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuSin of skusSinStock">{{skuSin.sinStock}}</h1>
              <p class="card-text text-center text-muted">Total Sku Sin Stock</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
           <!--begin::Background-->
           <span class="svg-icon svg-icon-2 me-2" style=" 
           position: absolute;
           top: 40%;
           left: 30%;
           width: 100%;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
              <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black"/>
              </svg>
            </span>
               <!--end::Background-->
            <div class="card-body">
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuCon of skusConStock">{{skuCon.conStock}}</h1>
              <p class="card-text text-center text-muted">Total Sku Con Stock</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
           <!--begin::Background-->
           <span class="svg-icon svg-icon-2 me-2" style=" 
           position: absolute;
           top: 40%;
           left: 30%;
           width: 100%;">
             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
              <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="black"/>
              <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="black"/>
              </svg>              
           </span> 
           <!--end::Background-->
            <div class="card-body">
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuHabilitado of skuHabilitados">{{skuHabilitado.noHabilitado}}</h1>
              <p class="card-text text-center text-muted">Total Sku No Habilitado</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
           
            <div class="card-body">
                <!--begin::Background-->
           <span class="svg-icon svg-icon-2 me-2" style=" 
           position: absolute;
           top: 40%;
           left: 30%;
           width: 100%;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
              <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black"/>
              </svg>
            </span>
               <!--end::Background-->
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuHabilitado of skuHabilitados">{{skuHabilitado.habilitado}}</h1>
              <p class="card-text text-center text-muted">Total Sku Habilitado</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
              <!--begin::Background-->
              <span class="svg-icon svg-icon-2 me-2" style=" 
              position: absolute;
              top: 40%;
              left: 30%;
              width: 100%;">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                  <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="black"/>
                  <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="black"/>
                  </svg>                  
              </span> 
              <!--end::Background-->
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuReseva of skuResevas">{{skuReseva.sinReserva}}</h1>
              <p class="card-text text-center text-muted">Total Sku Sin Reserva</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
               <!--begin::Background-->
               <span class="svg-icon svg-icon-2 me-2" style=" 
               position: absolute;
               top: 40%;
               left: 30%;
               width: 100%;">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="black"/>
                </svg>
                </span>
                   <!--end::Background-->
            <div class="card-body">
              <h1 class="card-title text-center  fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let skuReseva of skuResevas">{{skuReseva.conReserva}}</h1>
              <p class="card-text text-center text-muted">Total Sku Con Reserva</p>
              <div *ngIf="verData" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                <div>No hay datos para mostrar</div>
              </div>
            </div>
          </div>
        </div>
          <div class="col">
            <div class="card">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <div class="card-body">
                <h5 class="card-title text-center">Categorías</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-gray-400">Categoría</th>
                        <th class="text-gray-400" >Cantidad</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let skuCategoria of skuCategorias">
                      <td class="text-gray-800 fw-bold text-hover-primary fs-6">{{skuCategoria.categoria}}
                        <!-- <th ></th> -->
                      </td>
                    <td class="badge badge-primary badge-pill text-white fw-bold p-2 my-2 mx-5" style="margin-left: 30px !important;"> {{skuCategoria.cantidad}}                   
                      </td>

                    </tbody>
                  </table>
                  <div *ngIf="skuCategorias == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                    <div>No hay datos para mostrar</div>
                  </div>
                <!-- <ul class="list-group" *ngFor="let skuCategoria of skuCategorias">
                    <li class="list p-2 d-flex justify-content-between align-items-center">
                        {{skuCategoria.categoria}}
                      <span class="badge badge-primary badge-pill">{{skuCategoria.cantidad}}</span>
                    </li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <div class="card-body">
                <h5 class="card-title text-center">Marcas</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-gray-400">Marca</th>
                        <th class="text-gray-400" >Cantidad</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let skuMarca of skuMarcas">
                      <td class="text-gray-800 fw-bold text-hover-primary fs-6">{{skuMarca.marca}}
                        <!-- <th ></th> -->
                      </td>
                    <td class="badge badge-primary badge-pill text-white fw-bold p-2 my-2 mx-5" style="margin-left: 30px !important;"> {{skuMarca.cantidad}}                   
                    <!-- <span class="badge badge-primary badge-pill">{{skuMarca.cantidad}}</span> -->
                        <!-- <th>{{skuMarca.cantidad}}</th> -->
                      </td>

                    </tbody>
                  </table>
                  <div *ngIf="skuMarcas == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                    <div>No hay datos para mostrar</div>
                  </div>
                <!-- <ul class="list-group" *ngFor="let skuMarca of skuMarcas">
                    <li class="list p-2 d-flex justify-content-between align-items-center">
                        {{skuMarca.marca}}
                      <span class="badge badge-primary badge-pill">{{skuMarca.cantidad}}</span>
                    </li>
                </ul> -->
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <div class="card-body">
                <h5 class="card-title text-center">Tipos de Productos</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-gray-400">Tipo</th>
                        <th class="text-gray-400" >Cantidad</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let skuTipo of skuTipos">
                      <td class="text-gray-800 fw-bold text-hover-primary fs-6">{{skuTipo.tipo}}
                        <!-- <th ></th> -->
                      </td>
                    <td class="badge badge-primary badge-pill text-white fw-bold p-2 my-2 mx-5" style="margin-left: 30px !important;"> {{skuTipo.cantidad}}                   
                    <!-- <span class="badge badge-primary badge-pill">{{skuMarca.cantidad}}</span> -->
                        <!-- <th>{{skuMarca.cantidad}}</th> -->
                      </td>

                    </tbody>
                  </table>
                  <div *ngIf="skuTipos == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                    <div>No hay datos para mostrar</div>
                  </div>
                <!-- <ul class="list-group" *ngFor="let skuTipo of skuTipos">
                    <li class="list p-2 d-flex justify-content-between align-items-center">
                        {{skuTipo.tipo}}
                      <span class="badge badge-primary badge-pill">{{skuTipo.cantidad}}</span>
                    </li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <!-- <img src="..." class="card-img-top" alt="..."> -->
              <div class="card-body">
                <h5 class="card-title text-center">Tipos de Negocios</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-gray-400">Tipo</th>
                        <th class="text-gray-400" >Cantidad</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let skuTipoNegocio of skuTipoNegocios">
                      <td class="text-gray-800 fw-bold text-hover-primary fs-6">{{skuTipoNegocio.tipo}}
                        <!-- <th ></th> -->
                      </td>
                    <td class="badge badge-primary badge-pill text-white fw-bold p-2 my-2 mx-5" style="margin-left: 30px !important;"> {{skuTipoNegocio.cantidad}}                   
                      </td>

                    </tbody>
                  </table>
                  <div *ngIf="skuTipoNegocios == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                    <div>No hay datos para mostrar</div>
                  </div>
                <!-- <ul class="list-group" *ngFor="let skuTipoNegocio of skuTipoNegocios">
                    <li class="list p-2 d-flex justify-content-between align-items-center">
                        {{skuTipoNegocio.tipo}}
                      <span class="badge badge-primary badge-pill">{{skuTipoNegocio.cantidad}}</span>
                    </li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
                <!--begin::Background-->
                <!-- <div
                class="position-absolute top-0 end-0 opacity-10 pe-none text-end"
              >
                <img
                  src="assets/media/icons/duotune/ecommerce/ecm001.svg"
                  class="w-175px"
                />
              </div> -->
              <!--end::Background-->
              <div class="card-body">
                <h5 class="card-title text-center">Estados</h5>
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-gray-400">Estado</th>
                        <th class="text-gray-400" >Cantidad</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let skuEstado of skuEstados">
                      <td class="text-gray-800 fw-bold text-hover-primary fs-6">{{skuEstado.estado}}
                        <!-- <th ></th> -->
                      </td>
                    <td class="badge badge-primary badge-pill text-white fw-bold p-2 my-2 mx-5" style="margin-left: 30px !important;"> {{skuEstado.cantidad}}                   
                      </td>

                    </tbody>
                  </table>
                  <div *ngIf="skuEstados == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                    <div>No hay datos para mostrar</div>
                  </div>
                <!-- <ul class="list-group" *ngFor="let skuEstado of skuEstados">
                    <li class="list p-2 d-flex justify-content-between align-items-center">
                        {{skuEstado.estado}}
                      <span class="badge badge-primary badge-pill">{{skuEstado.cantidad}}</span>
                    </li>
                </ul> -->
              </div>
            </div>
          </div>
    </div>
