import { Component, OnInit } from '@angular/core';
import { PermisosRol } from 'src/app/models/permisosApp';
import { UsuarioService } from '../../../../../modules/auth/services/usuario.service';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {

  showDashboard = false;
  showProduct = false;
  showSales = false;
  showMaintainers = false;
  showLead = false;
  showCampaign = false;
  showReporteVentaConfig =false;
  showQuoteConfig = false;
  showCarrousel = false;

  constructor(private userService:UsuarioService) {
    this.showDashboard = userService.hasPermission(PermisosRol.VER_DASHBOARD);
    this.showProduct = userService.hasPermission(PermisosRol.VER_PRODUCTO);
    this.showSales = userService.hasPermission(PermisosRol.VER_VENTAS);
    this.showMaintainers = userService.hasPermission(PermisosRol.VER_CONFIGURACION);
    this.showLead = userService.hasPermission(PermisosRol.VER_LEADS);
    this.showCampaign = userService.hasPermission(PermisosRol.VER_CAMPANIA);
    this.showReporteVentaConfig = userService.adminAibo;
    this.showQuoteConfig = userService.adminAibo;
    this.showCarrousel = userService.adminAibo;
  }

  ngOnInit(): void {}
}
