import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoImagenPipe } from './no-imagen.pipe';
import { NumberFormatPipe } from './number.pipe';
import { FiltroFechaPipe } from './filtro.pipe';
import { SafePipe } from './safe.pipe';
import { GetDescountPipe } from './get-descount.pipe';
import { RutFormatPipe } from './rutFormat.pipe';
import { CapitalizePipe } from './capitalize.pipe';



@NgModule({
  declarations: [
    NoImagenPipe,
    NumberFormatPipe,
    FiltroFechaPipe,
    SafePipe,
    GetDescountPipe,
    RutFormatPipe,
    CapitalizePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NoImagenPipe,
    NumberFormatPipe,
    FiltroFechaPipe,
    SafePipe,
    GetDescountPipe,
    RutFormatPipe,
    CapitalizePipe
  ]
})
export class PipeModule { }
