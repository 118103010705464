import { Pipe, PipeTransform } from '@angular/core';
import { Producto } from 'src/app/models/product';

@Pipe({
  name: 'noImagen'
})
export class NoImagenPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    let resp = 'https://thumbs.dreamstime.com/z/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg';
    if(value.tbl_documentos && value.tbl_documentos.length > 0){
      const doc  = value.tbl_documentos.filter((doc:any)=>doc.int_tipo==1 && doc.estado && doc.bit_fotoportada);
      if(doc.length > 0) resp = doc[0].vch_url
    }
    // console.log('respuesta del pipe' ,resp);
    
    return resp;
  }

}
