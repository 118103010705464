import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { UsuarioService } from './usuario.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: UsuarioService,
              private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try{
      const resp = await firstValueFrom(this.authService.verificaToken());
      console.log('me ejecuto como guard', resp, this.authService.userToken);
      if(resp.code != 3){
        this.authService.usuario = resp.data.usuario;
        this.authService.rol = resp.data.rol;
        // si viene 2 es admin aibo, cualquier otro numero no es admin
        this.authService.adminAibo = (resp.code == 1) ? false: true;
        return true;
      }
      this.router.navigate(['auth']);
      return false;
    }
    catch(ex){
      console.error('Se cayó el server',ex);
      
      this.router.navigate(['auth']);
      return false;
    }
    /*
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
    */
  }
}
