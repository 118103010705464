import { DashboardProductosComponent } from './pages/components/dashboard-productos/dashboard-productos.component';
import { PipeModule } from './modules/pipes/pipe.module';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { NgChartsModule } from 'ng2-charts';
import { WidgetsModule } from './sellercenter/partials/content/widgets/widgets.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketPromedioComponent } from './pages/components/ticket-promedio/ticket-promedio.component';
import { PieChartComponent } from './pages/components/pie-chart/pie-chart.component';
import { DahboardRutesComponent } from './pages/dahboard-rutes.component';
import { DashboardComponent } from './pages/components/dashboard/dashboard.component';
import { ProductosVendidosComponent } from './pages/components/productos-vendidos/productos-vendidos.component';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TicketPromedioComponent,
    ProductosVendidosComponent,
    PieChartComponent,
    DahboardRutesComponent,
    DashboardProductosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    TranslateModule.forRoot(),
    NgChartsModule,
    WidgetsModule,
    PipeModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
