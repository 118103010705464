import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rutFormat'
})
export class RutFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const cleanRut = value.replace(/[.-]/g, '');

    // Formatear el RUT con puntos y guiones
    const formattedRut = cleanRut.replace(/^(\d{1,3})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');

    return formattedRut;
  }
}
