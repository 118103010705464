import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {
    let resp = '';
    try {
      if(value == '') return '';
      resp = value.replace(/^\w/, (c) => c.toUpperCase()); 
    } catch (error) {
      resp = value;
    }
    return resp;
  }

}
