// import { Pipe, PipeTransform } from '@angular/core';
// import { Venta, Comprador } from 'src/app/models/venta';

// @Pipe({
//   name: 'filtro'
// })
// export class FiltroPipe implements PipeTransform {
//   transform(ventas: Venta[], search:any):Venta[] {
//       // console.log(search)
//      const filteredVentas = ventas.filter(venta =>  
//       venta.id?.toFixed(search) ||
//       // venta.id?(search) || 
//       venta.tbl_comprador?.vch_nombre?.includes(search) || venta.tbl_comprador?.vch_rut?.includes(search)
//       )
//      return filteredVentas;
// }
// }

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filtroFecha'
})
export class FiltroFechaPipe implements PipeTransform {
  transform(row: any, f1: Date, f2?: Date): any {
    f1.toString().length == 0 ? f1 = new Date("1995-12-25T11:30:00.000Z") : f1;
    f2 == null ? f2 = new Date() :f2; 
    if (f1 >= f2 || f1 == null) { return row;}
    // return row.filter(x =>{return  new Date(x.fecha) >= new Date(f1) && new Date(x.fecha) <= new Date(f2)});   
  }
}
