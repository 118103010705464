import { Venta, VentasResponse } from 'src/app/models/venta';
import { Component, OnInit, ViewChild, Input  } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  venta: VentasResponse;
  ventas : Venta[];
  productos: Venta[];
  tickets: Venta[];
  tipos: Venta[]
  f: NgForm;
  myGroup: FormGroup;
  formData: any;
  loading = true;
  TotalM: number = 0;
  filterActive: boolean = false;


  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  constructor(private dashboardProvider: DashboardService,
    private formBuilder: FormBuilder,
    private userService: UsuarioService,
  ) { }

  ngOnInit(): void {
    this.myGroup = this.formBuilder.group({
      mes: '',
      tipoProducto: '',
  });
  }


  // Filtro
  onSubmit( form: NgForm ) {
    const idEmpresa = this.userService.usuario.id_empresa; 
  this.formData = this.myGroup.value;
  // console.log(this.myGroup.value);
    let search:any = {}
      if(this.formData['mes']){
        search.mes = this.formData['mes']
      }
      if(this.formData['tipoProducto']){
        search.tipoProducto = this.formData['tipoProducto']
      }
      this.filterActive = true;
      // console.log(search)
      // const idEmpresa = userService.usuario.id_empresa 
      this.dashboardProvider.getVentasMensuales(search, idEmpresa).subscribe((venta: any)=>{
        this.ventas = venta.data;
      })
         
  }

  reset(){
    this.myGroup.reset({
      mes: '',
      tipoProducto:'',
    });
  } 
 
}