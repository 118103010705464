import { Pipe, PipeTransform } from '@angular/core';
import { Producto, ProductoModal } from 'src/app/models/product';

@Pipe({
  name: 'getDescount',
  pure: true
})
export class GetDescountPipe implements PipeTransform {

  transform(product: Producto | ProductoModal, campain: boolean, reload:boolean): number {
    let resp = 0;
    console.log('me ejecuto pipe');
    
    if(campain){
      //* EN CAMPAÑA
      resp = ((product.int_porcdescuento > 0) )
              ? product.db_precio  - (product.db_precio * (product.int_porcdescuento/100)) //* si tiene porc desc el producto
              : (product.tbl_productoxcategoria[0].int_porcdescuento != 0)
                  ? product.db_precio  - (product.db_precio * (product.tbl_productoxcategoria[0].int_porcdescuento/100)) //* si tiene porc desc la campaña
                  : product.db_precio  - product.db_descuento;
    } else {
      //* SIN CAMPAÑA
      resp = ((product.int_porcdescuento > 0) )
              ? product.db_precio  - (product.db_precio * (product.int_porcdescuento/100)) //* si tiene porc desc el producto
              : product.db_precio  - product.db_descuento;
    }
   
    return resp;                               
  }

}
