import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Venta } from 'src/app/models/venta';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-productos-vendidos',
  templateUrl: './productos-vendidos.component.html',
  styleUrls: ['./productos-vendidos.component.scss']
})
export class ProductosVendidosComponent implements OnInit, OnChanges{
  @Input() form: FormData;
  productos: Venta[] | any;
  ventas: Venta[];
  constructor(
    private dashboardProvider: DashboardService,
    private userService: UsuarioService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
     this.montoMensual();
    this.productosVendidos();
    console.log(changes);
  }

  ngOnInit(): void {
     this.montoMensual();
    this.productosVendidos();
  }

  montoMensual(){
  const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;
  this.dashboardProvider.getVentasMensuales(search, idEmpresa).subscribe((venta: any)=>{
    this.ventas = venta.data;
  })
}

  productosVendidos(){
    const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;
  this.dashboardProvider.getProductosVendidos(search, idEmpresa).subscribe((producto: any)=>{
    this.productos = producto.data;
    // console.log(this.productos)
  })
}
}
