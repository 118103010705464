import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VentasResponse } from 'src/app/models/venta';
import { DashboardResponse } from 'src/app/models/product';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  // Dashboard Ventas

  getVentasMensuales(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/ventasMensuales/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }

  getVentasUltimosMeses(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/ventasUltimosMeses/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }

  getVentasMesxDia(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/ventasMesxDia/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }

  getTicketPromedio(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/TicketPromedio/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }
    
  getProductosVendidos(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/ProductosVendidos/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }

  getProdVendidosxTipo(body:any, idEmpresa:string):Observable<VentasResponse>{
    const url = `${this.apiURL}dashboard/ProdVendidosxTipo/${idEmpresa}`;
    return this.http.post<VentasResponse>(url,body);
    }

  // Dashboard Productos
  getTotalSKU(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/TotalSKU/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
    }

  getSinStock(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/SinStock/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getConStock(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/ConStock/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getHabilitado(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/Habilitado/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getporEstado(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/porEstado/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getporMarca(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/porMarca/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getReserva(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/reserva/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getporTipo(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/porTipo/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }

   getporCategoria(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/porCategoria/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }
   getporTipoNegocio(body:any, idEmpresa:string):Observable<DashboardResponse>{
    const url = `${this.apiURL}dashboardProductos/porTipoNegocio/${idEmpresa}`;
    return this.http.post<DashboardResponse>(url,body);
   }
}
