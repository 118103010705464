<div class="card card-flush">
    <!--begin::Header-->
    <div class="card-header pt-5">
        <!--begin::Title-->
        <div class="card-title d-flex flex-column">
            <!--begin::Info-->
            <div class="d-flex align-items-center">
                <!--begin::Currency-->
                <!-- <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span> -->
                <!--end::Currency-->
                <!--begin::Amount-->
                <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2" *ngFor="let venta of ventas">{{venta.Total | numberFormat}}</span>
                <!--end::Amount-->
                <!--begin::Badge-->
                <!-- <span class="badge badge-light-success fs-base"> -->
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                <!-- <span class="svg-icon svg-icon-5 svg-icon-success ms-n1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                    </svg>
                </span> -->
                <!-- end::Svg Icon2.2%</span> -->
                <!--end::Badge-->
            </div>
            <!--end::Info-->
            <!--begin::Subtitle-->
            <span class="text-gray-400 pt-1 fw-semibold fs-6">Ventas del Mes</span>
            <!--end::Subtitle-->
        </div>
        <!--end::Title-->
    </div>
    <!-- Mensaje no data -->
    <div *ngIf="tipos == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
        <div>No hay datos para mostrar</div>
      </div>
    <!--end::Header-->
    <div class="d-flex flex-center me-5 pt-2" >
        <div id="kt_card_widget_4_chart" style="min-width: 70px; min-height: 70px;" data-kt-size="70" data-kt-line="11">
                    <canvas baseChart
                    [data]="pieChartData"
                    [type]="pieChartType"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins">
            </canvas>
                
        </div>
    </div>

    <!--begin::Card body-->
    <div class="card-body pt-2 pb-4 d-flex align-items-center">
        <!--begin::Chart-->
        <!--end::Chart-->
        <!--begin::Labels-->
        <div class="d-flex flex-column content-justify-center w-100" *ngFor="let tipo of tipos">
            <!--begin::Label-->
            <div class="d-flex fs-6 fw-semibold align-items-center">
                <!--begin::Bullet-->
                <!-- <div class="bullet w-8px h-6px rounded-2 bg-danger me-3"></div> -->
                <!--end::Bullet-->
                <!--begin::Label-->
                <!-- <div class="text-gray-500 flex-grow-1 me-4">{{tipo.tipo}}</div> -->
                <!--end::Label-->
                <!--begin::Stats-->
                <!-- <div class="fw-bolder text-gray-700 text-xxl-end">{{tipo.Total | currency:'$':'symbol':'3.0'}}</div> -->
                <!--end::Stats-->
            </div>
            <!--end::Label-->
            <!--begin::Label-->
            
            <!--end::Label-->
        </div>
    </div>
        <!--end::Labels-->
    <!--end::Card body-->
</div>
