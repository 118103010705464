<!--begin::Content-->
<!-- <div class="content d-flex flex-column flex-column-fluid" id="kt_content"> -->
    <!--begin::Toolbar-->
   <!--begin::Main-->
    <!-- <div class="app-main flex-column flex-row-fluid" id="kt_app_main"> -->
        <!--begin::Content wrapper-->
        <!-- <div class="d-flex flex-column flex-column-fluid"> -->
            <!--begin::Toolbar-->
            <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
                <!--begin::Toolbar container-->
                <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                    <!--begin::Page title-->
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <!--begin::Title-->
                        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard - AIBOMarket</h1>
                        <!--end::Title-->
                        <!--begin::Breadcrumb-->
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <!--begin::Item-->
                            <li class="breadcrumb-item text-muted">Inicio</li>
                            <!--end::Item-->
                            <!--begin::Item-->
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <!--end::Item-->
                            <!--begin::Item-->
                            <li class="breadcrumb-item text-muted">Dashboards</li>
                            <!--begin::Item-->
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <!--end::Item-->
                            <li class="breadcrumb-item text-muted">Ventas</li>
                            <!--end::Item-->
                        </ul>
                        <!--end::Breadcrumb-->
                    </div>
                    <!--end::Page title-->
                    <!--begin::Actions-->
                    <!--div class="d-flex align-items-center gap-2 gap-lg-3">
                        <!--begin::Secondary button- ->
                        <a href="../../demo1/dist/apps/ecommerce/sales/listing.html" class="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary">Manage Sales</a>
                        <!--end::Secondary button- ->
                        <!--begin::Primary button- ->
                        <a href="../../demo1/dist/apps/ecommerce/catalog/add-product.html" class="btn btn-sm fw-bold btn-primary">Add Product</a>
                        <!--end::Primary button- ->
                    </div-->
                    <!--end::Actions-->
                </div>
                 <!-- Estado -->
<form (ngSubmit)="onSubmit(f)"  [formGroup]="myGroup"  >
    <div class="row mt-5">
        <div class="col">
            <select
            class="form-select mb-2"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Select an option" 
            formControlName="mes"
            name="mes Venta">
            <!-- <option value="">Estado</option> -->
            <option value="">Mes</option>
            <option value="Jan">Enero</option>
            <option value="Feb">Febrero</option>
            <option value="Mar">Marzo</option>
            <option value="Apr">Abril</option>
            <option value="May">Mayo</option>
            <option value="Jun">Junio</option>
            <option value="Jul">Julio</option>
            <option value="Aug">Agosto</option>
            <option value="Sep">Septiembre</option>
            <option value="Oct">Octubre</option>
            <option value="Nov">Noviembre</option>
            <option value="Dec">Diciembre</option>
            </select>
        </div> 
        <!-- Tipo de Negocio -->
        <div class="col">
            <select
            class="form-select mb-2"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Select an option" 
            formControlName="tipoProducto"
            name="tipo Producto">
            <!-- <option value="">Estado</option> -->
            <option value="">Tipo de Producto</option>
              <option value="3739c6d3-6520-11ed-93f9-0eade412ab1d">Comodato</option>
              <option value="4924f12f-678f-11ed-93f9-0eade412ab1d">Curso</option>
              <option value="4fa1de07-0df2-11ed-9d86-065d6ce605ce">Servicio</option>
              <option value="4fa1e0d6-0df2-11ed-9d86-065d6ce605ce">Equipamiento</option>
              <option value="4fa1e1b0-0df2-11ed-9d86-065d6ce605ce">Repuestos</option>
              <option value="b17bd51a-2cc1-11ed-9d86-065d6ce605ce">Insumos</option>
            </select>
        </div>
    </div>
    <input type="submit" class="btn btn-sm btn-primary mt-5" value="Buscar" (click)="onSubmit(f)">
    <input type="submit" value="Quitar Filtros" class="btn btn-sm btn-danger mt-5 mx-5" (click)="reset()" *ngIf="filterActive" />
</form>
                <!--end::Toolbar container-->
            </div>
            <!--end::Toolbar-->
            <!--begin::Content-->
            <!-- <div id="kt_app_content" class="app-content flex-column-fluid"> -->
                <!--begin::Content container-->
                <!-- <div id="kt_app_content_container" class="app-container container-xxl"> -->
                    <!--begin::Row-->
                    <div class="row">
                            <!--begin::Col-->
                            <!-- Venta Mensual -->
                            <div class="col pb-7">
                                    <!--begin::Card widget 4-->
                                    <app-pie-chart [form]="formData"></app-pie-chart>
                            </div>
                           <!-- Ticket Promedio -->
                            <div class="col pb-7">
                            <app-ticket-promedio [form]="formData"></app-ticket-promedio>
                        </div>
                    </div>
          
                            <!--end::Card widget 5-->
                        <!--end::Col-->
                 
                        <!-- Productos Vendidos -->
            
                    <div class="row">
                        <div class="col pb-7">
                            <app-productos-vendidos [form]="formData"></app-productos-vendidos>
                          </div>
                    </div> 
                  
                        <!--end::Col-->
                        <!-- Venta últimos meses -->


                                <!--begin::Card body-->
                                <div class="row">
                                    <!--begin::Chart-->
                                    <div class="col">
                                        <app-charts-widget1 [form]="formData" class="card card-xl-stretch mb-xl-8">
                                        </app-charts-widget1>
                                    </div>
                                    <!--end::Chart-->
                                </div>
                                <!--end::Card body-->
                        
                        <!-- Ventas del Mes por día -->
                                <!--begin::Chart-->
                                <div class="row ">
                                    <div class="col">
                                        <app-charts-widget3 [form]="formData" class="card card-xl-stretch mb-xl-8">
                                        </app-charts-widget3>
                                    </div>
                                </div>
                                <!--end::Chart-->
                <!-- </div> -->
            <!-- </div> -->
        <!-- </div> -->
    <!-- </div> -->
<!-- </div> -->

<!--end::Content-->


