<div
  [ngClass]="footerContainerCssClasses"
  class="
    d-flex
    flex-column flex-md-row
    align-items-center
    justify-content-between
  "
>
  <!-- begin::Copyright  -->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted fw-bold me-2">{{ currentDateStr }} &copy;</span>
    <a routerLink="/dashboard">
      <img
        alt="Logo"
        class="h-25px logo"
        src="./assets/media/logos/logoaibonegro.svg"
      />
    </a>
  </div>
  <!-- end::Copyright  -->

  <div class="order-1">
    <h4><img class="footer-flag-desktop" src="./assets/media/flags/chile.svg" alt="bandera-chile" height="15" width="15">
      Chile:</h4>
      <p>Ejército Libertador 733, Santiago, RM.</p>
    
  </div>
  
  <div class="order-3">
      <h4><img class="footer-flag-desktop" src="./assets/media/flags/peru.svg" alt="bandera-peru" height="15" width="15">
        Perú:</h4>
        <p>Av. Elmer Faucett 217, Lima 15088.</p>
  </div>
  <!-- <div class="order-3">
    <h4><img class="footer-flag-desktop" src="./assets/flag-peru.png" alt="bandera-peru">
      Chile:</h4>
      <p>Av. Elmer Faucett 217, Lima 15088.</p>
  </div> -->

  <!-- begin::Nav  -->
  <!-- <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-4">
    <li class="menu-item">
      <a class="menu-link ps-0 pe-2 cursor-pointer" href="https://desarrollo.aibomarket.com/quienes-somos"> Quiénes somos </a>
    </li>
    <li class="menu-item">
      <a class="menu-link pe-0 pe-2 cursor-pointer" href="https://desarrollo.aibomarket.com/terminos">Términos y condiciones</a>
    </li>
    <li class="menu-item">
      <a class="menu-link pe-0 cursor-pointer" href="https://desarrollo.aibomarket.com/politicas">Política de privacidad</a>
    </li>
  </ul> -->

  <!-- end::Nav  -->

  
</div>
