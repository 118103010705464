import { Routes } from '@angular/router';
import { AuthGuard } from '../modules/auth/services/auth.guard';
import { DashboardProductosComponent } from './components/dashboard-productos/dashboard-productos.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DahboardRutesComponent } from './dahboard-rutes.component';

const Routing: Routes = [
  {
    path: 'dashboard',
    component: DahboardRutesComponent,
    children: [
      {
        path: 'ventas',
        component: DashboardComponent,
      },
      {
        path: 'productos',
        component: DashboardProductosComponent,
      },
    ],
  },
  {
    path: 'product',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('../modules/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'sales',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('../modules/sales/sales.module').then((m) => m.SalesModule),
  },
  {
    path: 'mantenedores',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('../modules/mantenedores/mantenedores.module').then((m) => m.MantenedoresModule),
  },
  {
    path: '',
    redirectTo: 'dashboard/ventas', // será el dashboard
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
