import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Venta } from 'src/app/models/venta';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-ticket-promedio',
  templateUrl: './ticket-promedio.component.html',
  styleUrls: ['./ticket-promedio.component.scss']
})
export class TicketPromedioComponent implements OnInit, OnChanges  {
  @Input() form: FormData;
  tickets: Venta[] | any;
  constructor(
    private dashboardProvider: DashboardService,
    private userService: UsuarioService,) { }

    ngOnChanges(changes: SimpleChanges): void {
      this.ticketPromedio()
      console.log(changes);
    }
  
    ngOnInit(): void {
      this.ticketPromedio()
    }

ticketPromedio(){
  const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;
  this.dashboardProvider.getTicketPromedio( search,idEmpresa).subscribe((ticket: any)=>{
    this.tickets = ticket.data;
    // console.log(this.tickets)
  })
}
}
