<!-- begin::Brand -->
<div class="aside-logo flex-column-auto" id="kt_aside_logo">
  <!-- begin::Logo -->
  <ng-container *ngIf="asideTheme === 'dark'">
    <a routerLink="/dashboard">
      <img
        alt="Logo"
        class="h-25px logo"
        src="./assets/media/logos/logoaiboblanco.svg"
      />
    </a>
  </ng-container>
  <!-- src="./assets/media/logos/logo-1-dark.svg" -->

  <ng-container *ngIf="asideTheme === 'light'">
    <a routerLink="/dashboard">
      <img
        alt="Logo"
        class="h-25px logo"
        src="./assets/media/logos/logoaibo.png"
      />
    </a>
  </ng-container>

  <ng-container>
      <img
        alt="Logo"
        class="h-25px logo"
        [src]="emp"
      />
  </ng-container>

  <!-- src="./assets/media/logos/logo-1.svg" -->

  <!-- end::Logo -->

  <!-- begin::Aside toggle -->
  <ng-container *ngIf="asideMinimize">
    <div
      id="kt_aside_toggle"
      class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
      data-kt-toggle="true"
      data-kt-toggle-state="active"
      data-kt-toggle-target="body"
      data-kt-toggle-name="aside-minimize"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/arr079.svg'"
        class="svg-icon svg-icon-1 rotate-180"
      ></span>
    </div>
  </ng-container>
  <!-- end::Aside toggler -->
</div>
<!-- end::Brand  -->

<!-- begin::Aside menu -->
<div class="aside-menu flex-column-fluid">
  <div
    #ktAsideScroll
    id="kt_aside_menu_wrapper"
    class="hover-scroll-overlay-y my-5 my-lg-5"
    data-kt-scroll="true"
    data-kt-scroll-activate="{default: false, lg: true}"
    data-kt-scroll-height="auto"
    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
    data-kt-scroll-wrappers="#kt_aside_menu"
    data-kt-scroll-offset="0"
  >
    <div
      id="#kt_aside_menu"
      data-kt-menu="true"
      class="
        menu
        menu-column
        menu-title-gray-800
        menu-state-title-primary
        menu-state-icon-primary
        menu-state-bullet-primary
        menu-arrow-gray-500
      "
    >
      <app-aside-menu id="asideMenu"></app-aside-menu>
    </div>
  </div>



</div>

<div class="d-flex align-items-center justify-content-center w-100 position-absolute" style="bottom: 60px;" *ngIf="developMode">
  <img
    alt=""
    class="h-50px"
    src="./assets/media/illustrations/testing.gif"
  />
  <img
    alt="Logo"
    class="img-thumbnail h-100px"
    src="./assets/media/illustrations/working.gif"
  />
</div>

<div class="d-flex align-items-center justify-content-center w-100 position-absolute" style="bottom: 20px;">

  
  <!-- <button type="button" class="btn btn-primary btn-circle btn-xl" routerLink="auth">
  </button> -->
  <h3 routerLink="auth" class="text-white m-0 me-2 cursor-pointer">Cerrar sesión</h3>
  <i class="fa-solid fa-right-from-bracket fa-2x" routerLink="auth" style="color:#0E74B5; cursor:pointer;"></i>

  


</div>



<!-- end::Aside menu -->
