

<!-- Dashboard -->
<div class="menu-item" *ngIf="showDashboard"
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show">
  <span class="menu-link"
      ><span class="menu-icon"
        ><span
          class="svg-icon svg-icon-2"
          [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'"
        ></span></span
      ><span class="menu-title" data-link="/crafted/account">Dashboard</span
      ><span class="menu-arrow"></span
    ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/dashboard/ventas"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Reporte de ventas</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/dashboard/productos"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Reporte de productos</span></a
      >
    </div>

  </div>
</div>



<!-- Productos-->
<div class="menu-item" *ngIf="showProduct">
  <a
    class="menu-link without-sub"
    routerLink="/product/list"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm009.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="Productos"></span
  ></a>
</div>



<div class="menu-item" *ngIf="showSales">
  <a
    class="menu-link without-sub"
    routerLink="/sales/list"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm005.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="Ventas"></span
  ></a>
</div>

<div class="menu-item" *ngIf="showSales">
  <a
    class="menu-link without-sub"
    routerLink="/sales/pedidos"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm005.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="Pedidos"></span
  ></a>
</div>

<div class="menu-item" *ngIf="showCampaign">
  <a
    class="menu-link without-sub"
    routerLink="/product/campaign"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm005.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="Campañas"></span
  ></a>
</div>

<div class="menu-item" *ngIf="showLead">
  <a
    class="menu-link without-sub"
    routerLink="/sales/lead"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm005.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="Leads"></span
  ></a>
</div>


<!-- Mantenedores -->
<div class="menu-item" *ngIf="showMaintainers"
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show">
  <span class="menu-link"
      ><span class="menu-icon"
        ><span
          class="svg-icon svg-icon-2"
          [inlineSVG]="'./assets/media/icons/duotune/coding/cod001.svg'"
        ></span></span
      ><span class="menu-title" data-link="/crafted/account">Configuración</span
      ><span class="menu-arrow"></span
    ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/tags"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Tags</span></a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/banner"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Banners</span></a>
    </div>

    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/bodegaslist"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Bodegas</span></a>
    </div>

    <!--   CATEGORIAS -->
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/categorias"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Categorías</span></a
      >
    </div>

    <div class="menu-item" *ngIf="showCarrousel">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/carrousel"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Carrusel</span></a>
    </div>

    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/tecnicolist"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Profesionales</span></a>
    </div>

    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/skusync"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Sku</span></a>
    </div>

    <!-- Shipping -->
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/shipping"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Costos de envío</span></a>
    </div>

    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/mantenedores/bodegaslist"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Bodegas</span></a>
    </div>
    <div class="menu-item" *ngIf="showReporteVentaConfig">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/sales/report-excel"ui
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Reporte ventas excel</span></a>
    </div>
    <div class="menu-item" *ngIf="showQuoteConfig">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/sales/cotizacion"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Cotizaciones</span></a>
    </div>

    <div class="menu-item" *ngIf="showQuoteConfig">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="mantenedores/client"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Clientes</span></a>
    </div>
  </div>
</div>

<!-- <div class="menu-item">
  <a
    class="menu-link without-sub"
    routerLinkActive="active"
    routerLink="/mantenedores/lista"
    ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
    ><span class="menu-title">Mis Categorías</span></a
  >
</div>
<div class="menu-item">
  <a
    class="menu-link without-sub"
    routerLinkActive="active"
    routerLink="/mantenedores/categoria/-1"
    ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
    ><span class="menu-title">+ Categoría</span></a
  >
</div> -->

<!-- Configuración -->
<!-- <div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
> -->
