<div class="card card-flush h-xl-100">
                              <div class="card-body">
                                <h4 class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2" *ngFor="let venta of ventas">{{venta.Total | numberFormat}}</h4>
                                <p class="fs-6 fw-semibold text-gray-400">
                                    Productos Vendidos (80/20)
                                </p>
                                <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th scope="col"></th>
                                        <th class="text-gray-400" scope="col">Producto</th>
                                        <th class="text-gray-400 text-end" scope="col">Cantidad</th>
                                        <th class="text-gray-400 text-end" style="padding-right: 15px;" scope="col">Venta</th>
                                      </tr>
                                    </thead>
                                    <tbody *ngFor="let producto of productos">
                                      <tr>
                                        <th scope="row"><img src="{{producto.imagen}}" class="me-4 w-30px" style="border-radius: 4px" alt="" /></th>
                                        <td>
                                            <a href="#" class="text-gray-800 fw-bold text-hover-primary fs-6">{{producto.producto}}</a>
                                            <span class="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">{{producto.tipo}}</span> 
                                        </td>
                                        <td class="text-gray-800 fw-bold text-end" style="padding-right: 40px;">{{producto.cantidad}}</td>
                                        <td class="text-gray-800 fw-bold text-end" style="padding-right: 0px;">{{producto.Total | numberFormat}}<td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div *ngIf="productos == 0" class="alert alert-primary d-flex align-items-center m-5" role="alert">
                                  <div>No hay datos para mostrar</div>
                                </div>
                              </div>
                            </div>
