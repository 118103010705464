import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import ResponseAPI from 'src/app/models/ResponseApi';
import { Rol } from 'src/app/models/rol';
import { Usuario } from 'src/app/models/usuario';
import { environment } from 'src/environments/environment';
import { EmpresaResponse } from '../../../models/empresa';
import { PermisosRol } from 'src/app/models/permisosApp';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private apiURL = environment.apiUrl + 'usuario';
  private apiURLempresa = environment.apiUrl + 'empresa';

  userToken: string | any;
  adminAibo: boolean;
  usuario: Usuario;
  rol: Rol;

  constructor(private http: HttpClient) {
    this.leerToken();
  }

  login(email: string, pass: string): Observable<any> {
    const body = {
      vch_nombre: email,
      vch_pass: pass,
    };
    const url = `${this.apiURL}/loginUsuario`;
    return this.http.post<any>(url, body).pipe(
      map((resp) => {
        this.guardarToken(resp['token']);
        this.usuario = resp['usuario'];
        this.adminAibo = resp['admin'];
        this.rol = resp['rol'];
        return resp;
      })
    );
  }

  private guardarToken(token: string) {
    this.userToken = token;
    localStorage.setItem('token', token);
  }

  leerToken() {
    if ( localStorage.getItem('token') ) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = 'token';
    }
    return this.userToken;

  }

  verificaToken():Observable<ResponseAPI>{
    const url = `${this.apiURL}/token`;
    const header = new  HttpHeaders({
      'x-token': this.userToken
    });
    return this.http.get<ResponseAPI>(url,{ headers:header });
  }

  getEmpresa(): Observable<EmpresaResponse>{
    const url = `${this.apiURLempresa}/${this.usuario.id_empresa}`;
    return this.http.get<EmpresaResponse>(url);
  }

  /**
   * Método que identifica si tiene el permiso en el rol el usuario.
   * @param perm Permiso que se solicita preguntar, tipo PermisosROL.
   * @returns true si es que tiene permiso, de lo contrario false.
   */
  hasPermission(perm:PermisosRol){
    const allPerm = this.rol.permisos.find(p=>p.id==PermisosRol.TODOS);
    if(allPerm) return true;
    const permFound = this.rol.permisos.find(p=>p.id==perm);
    return (permFound != undefined);
  }

  firstPage(){
    const allPerm = this.rol.permisos.find(p=>p.id==PermisosRol.TODOS);
    if(allPerm) return '/dashboard/ventas';
    return (this.rol.vch_primerapagina) ? this.rol.vch_primerapagina : '/error/404';
  }
}
