import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Venta } from 'src/app/models/venta';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { NumberFormatPipe } from 'src/app/modules/pipes/number.pipe';
import { DashboardService } from 'src/app/pages/services/dashboard.service';
import { getCSSVariableValue } from '../../../../../kt/_utils';

@Component({
  selector: 'app-charts-widget1',
  templateUrl: './charts-widget1.component.html',
})
export class ChartsWidget1Component implements OnInit, OnChanges {
  @Input() form: FormData;
  chartOptions: any;
  load = true;
  ventas: Venta[] | any;
  totales: Venta[];
  numberFormat: NumberFormatPipe;

  constructor(
    private dashboardProvider: DashboardService,
    private userService: UsuarioService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.graficoMeses();
    console.log(changes);
  }
  ngOnInit(): void {
    this.graficoMeses();
  }
  graficoMeses() {
    const idEmpresa = this.userService.usuario.id_empresa;
    const search = this.form;

    this.dashboardProvider.getVentasUltimosMeses(search, idEmpresa).subscribe({
      next: (venta) => {
        if (venta.data) {
          const datosGrafica = venta.data.map((x) => x.Total);
          const mesesGrafica = venta.data.map((x) => x.Mes);

          this.chartOptions = getChartOptions(350, mesesGrafica!, datosGrafica);

          this.ventas = [...venta.data];
          window.dispatchEvent(new Event('resize'));
          console.log(datosGrafica);
          this.load = false;
        }
      },
      error: (err) => console.error(err),
    });

    this.dashboardProvider
      .getVentasMensuales(search, idEmpresa)
      .subscribe((total: any) => {
        this.totales = total.data;
        // console.log(this.ventas)
      });
  }
}

function getChartOptions(height: number, x: any[], y: any[]) {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-primary');
  const secondaryColor = getCSSVariableValue('--bs-gray-300');

  return {
    series: [
      {
        name: 'Venta',
        data: y,
        // data: [76, 85, 101, 98, 87, 105],
      },
      // {
      //   name: 'Revenue',
      //   data: [76, 85, 101, 98, 87, 105],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'category',
      categories: x,
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      // y: {
      //   formatter: function (val: number) {
      //     return '$' + val + ' thousands';
      //   },
      // },
      y: {
        formatter: function (
          value: number,
          decimalLength: number = 0,
          chunkDelimiter: string = '.',
          decimalDelimiter: string = ',',
          chunkLength: number = 3
        ): string {
          let result =
            '\\d(?=(\\d{' +
            chunkLength +
            '})+' +
            (decimalLength > 0 ? '\\D' : '$') +
            ')';
          let num = value.toFixed(Math.max(0, ~~decimalLength));

          return (
            '$' +
            (decimalDelimiter
              ? num.replace('.', decimalDelimiter)
              : num
            ).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter)
          );
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
