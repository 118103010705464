import { Component, OnInit } from '@angular/core';
import { DashboardProd } from 'src/app/models/product';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-dashboard-productos',
  templateUrl: './dashboard-productos.component.html',
  styleUrls: ['./dashboard-productos.component.scss']
})
export class DashboardProductosComponent implements OnInit {
  skus: DashboardProd[] | any;
  skusSinStock: DashboardProd[] | any;
  skusConStock: DashboardProd[] | any;
  skuHabilitados: DashboardProd[] | any;
  skuEstados: DashboardProd[] | any;
  skuMarcas: DashboardProd[] | any;
  skuResevas: DashboardProd[]| any;
  skuTipos: DashboardProd[] | any;
  skuCategorias: DashboardProd[] | any;
  skuTipoNegocios: DashboardProd[] | any;
  verData: boolean = false;

  constructor(
    private dashboardProvider: DashboardService,
    private userService: UsuarioService)
    { }

  ngOnInit(): void {
    this.onSubmit()
  }

  // Filtro
  onSubmit() {
    const idEmpresa = this.userService.usuario.id_empresa; 
  // console.log(this.myGroup.value);
  let search:any = {}
  // Total SKU
      this.dashboardProvider.getTotalSKU(search, idEmpresa).subscribe((sku)=>{
        this.skus = sku.data;
        console.log(this.skus)
        if(this.skus[0].totalSKU == 0){
          this.verData = true
        } 
        
      })
  // Total SKU SIN STOCK
  this.dashboardProvider.getSinStock(search, idEmpresa).subscribe((sku)=>{
    this.skusSinStock = sku.data;
    console.log(this.skusSinStock)
    if(this.skusSinStock[0].sinStock == null){
      this.verData = true
    } 
    console.log(this.skusSinStock[0].sinStock)
  })
  
  // Total SKU CON STOCK
  this.dashboardProvider.getConStock(search, idEmpresa).subscribe((sku)=>{
    this.skusConStock = sku.data;
    console.log(this.skusConStock)
    if(this.skusConStock[0].conStock == null){
      this.verData = true
    } 
    console.log(this.skusConStock[0].conStock
      )
  })
  
  // Total SKU Habilitado y no Habilitado
  this.dashboardProvider.getHabilitado(search, idEmpresa).subscribe((sku)=>{
    this.skuHabilitados = sku.data;
    console.log(this.skuHabilitados)
    if(this.skuHabilitados[0].habilitado == null || this.skuHabilitados[0].noHabilitado == null){
      this.verData = true
    } 
    console.log(this.skuHabilitados[0].habilitado)
  })
  // Estado SKU
  this.dashboardProvider.getporEstado(search, idEmpresa).subscribe((sku)=>{
    this.skuEstados = sku.data;
    console.log(this.skuEstados)
  })
   // Estado SKU
   this.dashboardProvider.getporMarca(search, idEmpresa).subscribe((sku)=>{
    this.skuMarcas = sku.data;
    console.log(this.skuMarcas)
  })
  // Reserva SKU
  this.dashboardProvider.getReserva(search, idEmpresa).subscribe((sku)=>{
  this.skuResevas = sku.data;
  console.log(this.skuResevas)
  if(this.skuResevas[0].conReserva == null || this.skuResevas[0].sinReserva == null){
    this.verData = true
  } 
  console.log(this.skuResevas[0].conReserva)
  })
  // Tipo SKU
  this.dashboardProvider.getporTipo(search, idEmpresa).subscribe((sku)=>{
  this.skuTipos = sku.data;
  console.log(this.skuTipos)
  })
  // Categoria SKU
  this.dashboardProvider.getporCategoria(search, idEmpresa).subscribe((sku)=>{
    this.skuCategorias = sku.data;
    console.log(this.skuCategorias)
    })
  // Tipo Negocio SKU
  this.dashboardProvider.getporTipoNegocio(search, idEmpresa).subscribe((sku)=>{
  this.skuTipoNegocios = sku.data;
  console.log(this.skuTipoNegocios)
  })

  }
}
